<template>
  <div class="text-container">
    Wir begleiten Sie mit unseren Netzwerkpartnern von der Auswahl des
    Standortes bis hin zur Finanzierung. Auch hier arbeiten wir mit mehreren
    Banken zusammen.
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir beraten Apothekengründer. Mit unseren Partnern finden wir den perfekten Standort und mögliche Finanzierungen.",
      },
    ],
  },
};
</script>